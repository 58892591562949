import React from "react"

import { HeroStyleConstrained } from "@styles/organisms/Hero"
import styled from "styled-components"
import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import HeroHead from "./HeroHead"
import Heading from "@components/ui/Heading"

const HeroDefaultStyle = styled(HeroStyleConstrained)`
  height: auto;
  background-color: transparent;

  &,
  .container {
    min-height: 149px;

    .hero-head {
      ${breakpointsMedia(breakpoints.phablet, "max")} {
        display: none;
      }
    }

    ${breakpointsMedia(breakpoints.phablet)} {
      min-height: 244px;
    }
  }
`

export default function Hero({ title, uri, heroTitle }) {
  const breadcrumbs = [
    {
      title: title,
    },
  ]

  return (
    <HeroDefaultStyle>
      <div className="container">
        <HeroHead hasHome={true} breadcrumbs={breadcrumbs} uri={uri} />
        <div className="inner">
          <div className="title-wrap">
            <Heading>{heroTitle || title}</Heading>
          </div>
        </div>
      </div>
    </HeroDefaultStyle>
  )
}
