import React from "react"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import { GetInTouch } from "../forms/GetInTouchForm"

const ContactFormSectionStyled = styled.section`
  position: relative;
  z-index: 1;

  .inner {
    width: 1011px;
    max-width: 100%;
    margin: auto;
    padding: 30px 25px;

    ${breakpointsMedia(breakpoints.tablet)} {
      padding: 60px 20px;
    }

    .form-wrapper {
      width: 666px;
      max-width: 100%;
      margin: auto;
    }
  }
`
export const ContactFormSection = () => {
  return (
    <ContactFormSectionStyled>
      <div className={"inner form-wrapped form-wrapped--lg"}>
        <div className="form-wrapper">
          <GetInTouch />
        </div>
      </div>
    </ContactFormSectionStyled>
  )
}

ContactFormSection.propTypes = {}
