import React, { useState } from "react"
import { Link } from "gatsby"
import { AjaxForm } from "./AjaxForm"
import * as PropTypes from "prop-types"
import Heading from "@components/ui/Heading"
import Prose from "@components/ui/prose"

export const GetInTouch = ({ title }) => {
  const [formSubject, setFormSubject] = useState("")
  return (
    <div className="get-in-touch-form-wrap">
      {title && (
        <Prose>
          <Heading node="h2" underlined>
            {title}
          </Heading>
        </Prose>
      )}
      <AjaxForm
        formName="get_in_touch"
        formSubject={formSubject}
        submitText={"Send"}
        onSubmitOk={data => {
          window.dataLayer.push({ event: "gtm.projectForm" })

          window.dataLayer.push({
            event: "gtm.enquiryFormSubmit",
            formLabel: "General Enquiry",
          })
        }}
      >
        <div className="form-row">
          <AjaxForm.TextArea
            label="Explain your project and the services you are interested in"
            name="explain_your_project"
            required={true}
            maxLength={5000}
          />
        </div>

        <div className="form-row no-grid">
          <AjaxForm.Select
            name="budget_range"
            label="Budget Range"
            required={true}
            options={[
              {
                label: `Select budget range`,
              },
              {
                label: `5k - 15k`,
              },
              {
                label: `15k - 30k`,
              },
              {
                label: `30k+`,
              },
            ]}
          />
        </div>

        <div className="form-row">
          <AjaxForm.TextInput
            label="First Name"
            name="first_name"
            placeholder="Your first name here..."
            required={true}
            maxLength={100}
          />
          <AjaxForm.TextInput
            label="Last Name"
            name="last_name"
            placeholder="Your last name here..."
            required={true}
            maxLength={100}
          />
        </div>

        <div className="form-row">
          <AjaxForm.EmailInput
            label="Email"
            name="email"
            placeholder="Your email here..."
            required={true}
            maxLength={100}
          />
          <AjaxForm.TextInput
            label="Phone Number"
            name="phone_number"
            placeholder="Your phone number here..."
            required={true}
            maxLength={100}
          />
        </div>

        <div className="form-row">
          <AjaxForm.TextInput
            label="Company"
            name="company"
            placeholder="Your website URL here..."
            maxLength={100}
            onChange={value => setFormSubject(value)}
            required={true}
          />
          <AjaxForm.SingleCheckbox
            label={
              <span>
                I have read and accept
                <Link
                  target="_blank"
                  to="/privacy-policy/"
                  style={{ marginLeft: "5px" }}
                >
                  T&amp;C
                </Link>
              </span>
            }
            name="t_and_c"
            required={true}
            description="Or upload it below"
          />
        </div>
      </AjaxForm>
    </div>
  )
}

GetInTouch.propTypes = {
  title: PropTypes.string,
}

GetInTouch.defaultProps = {
  title: `Tell us about your project`,
}
